<script>
  import dayjs from 'dayjs';
  import NumberFlow, { NumberFlowGroup } from '@number-flow/svelte';

  export let dateOfBirth;

  let now = dayjs(),
    years,
    decimals;

  $: {
    [years, decimals] = now
      .diff(dateOfBirth, 'year', true)
      .toFixed(9)
      .split('.');
  }

  setInterval(() => {
    now = dayjs();
  }, 500);
</script>

<dl class="font-medium uppercase">
  <dt
    class="pl-1 text-xl tracking-wider text-gray-400 md:pl-2 md:text-3xl dark:text-gray-600"
  >
    Age
  </dt>

  <dd class="text-gray-900 dark:text-gray-500">
    <NumberFlowGroup>
      <div class="-mt-8 text-gray-900 dark:text-gray-500">
        <NumberFlow value={years} class="text-7xl font-bold md:text-9xl" />

        <span class="text-3xl md:text-6xl">,</span>

        <NumberFlow
          value={decimals}
          format={{
            useGrouping: false,
          }}
          class="text-3xl tabular-nums md:text-6xl"
        />
      </div>
    </NumberFlowGroup>
  </dd>
</dl>
